// import React from 'react';
// import { Hero, Stats, Business, Billing, CardDeal, Testimonials, Clients, CTA, Footer } from '../components'; // Import necessary components

// const HomeLayout = () => (
//   <div>
//     <Hero />
//     <Stats />
//     <Business />
//     <Billing />
//     <CardDeal />
//     <Testimonials />
//     <Clients />
//     <CTA />
//     <Footer />
//   </div>
// );

// export default HomeLayout;
import styles from '../style';
import React from 'react';
import Navbar from '../components/Navbar'; // Adjust the import path based on your project structure
import { Hero, Business, Billing, Testimonials, CTA, Footer } from '../components'; // Import necessary components

const HomeLayout = ({ children }) => (
    <div className = {`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className = {`${styles.boxWidth}`}>
            <Navbar />
            <Hero />
            <Business />
            <Testimonials />
            <Billing />
            <CTA />
            <Footer />
        </div>
  </div>
);

export default HomeLayout;

