import styles from '../style';
import React from 'react';
import DashNavbar from '../components/DashNavbar'; // Adjust the import path based on your project structure
import { Hero, Business, Billing, Testimonials, CTA, Footer } from '../components'; // Import necessary components

const HomeLayout = ({ children }) => (
    <div className = {`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className = {`${styles.boxWidth}`}>
            <DashNavbar />
            <Hero />
            <Business />
            <Testimonials />
            <Billing />
            <CTA />
            <Footer />
        </div>
  </div>
);

export default HomeLayout;

