import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, goldstar, goldshield, goldgear } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "clients",
    title: "People",
  },
  {
    id: "contact",
    title: "Contact Us",
  },
  {
    id: "login",
    title: "Login",
  },
  {
    id: "myaccount",
    title:"My Account"
  },
  {
    id: "signout",
    title: "Sign Out",
  }
  
];

export const darkNavLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "login",
    title: "Login",
  }
]

export const features = [
  {
    id: "feature-1",
    icon: goldstar,
    title: "Results",
    content:
      "Using SSTech will reward in results for your company or business.",
  },
  {
    id: "feature-2",
    icon: goldshield,
    title: "100% Confidential",
    content:
      "We take proactive steps make sure your information is secure.",
  },
  {
    id: "feature-3",
    icon: goldgear,
    title: "Expertise",
    content:
      "We deliver cutting-edge solutions using our extensive experience.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Having great technology consulting like this is a dream come true!",
    name: "Jamie Kim",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "My opportunities break my limitations. SSTech pushes the boundaries of consulting.",
    name: "Henry Ross",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "Found their personal consulting for my business projects great and user friendly.",
    name: "Jackson Thorne",
    title: "Personal User",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "10+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "2+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$1M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/ss-technology-consultants-inc",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];