import React, { useEffect } from 'react';
import { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate
import { close, sstech_scrnshot2, menu } from '../assets';
import { navLinks } from '../constants';
import axios from 'axios';
import { UserContext } from '../context/userContext';
import { motion, useAnimation } from 'framer-motion';


const DashNavbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSignOut = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get('/signout');
      const { message } = response.data;
      console.log(message);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      controls.start({ y: [0, -15, 0], transition: { duration: 3, repeat: Infinity } });
    }, 3000);

    return () => clearInterval(interval);
  }, [controls]);

  return (
    <nav className="w-full flex py-6 justify-between items-center navbar">
      {/* <img src={sstech_scrnshot2} alt="sstech" className="w-[130px] h-auto rounded-md" /> */}

      <div className={`flexLeft flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-col">
          {!!user && (
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
              className="font-poppins font-semibold ss:text-[45px] text-[45px] text-white ss:leading-[100.8px] leading-[75px] mb-2 text-center"
              style={{ marginLeft: '20px' }}
            >
              Hi {user.user.charAt(0).toUpperCase() + user.user.slice(1)}!
            </motion.h1>
          )}
        </div>
      </div>

      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          (nav.title === "Login" && !user) ? null : (nav.title === "Login" && user) ? (
            <li key={nav.id} className={`font-poppins font-normal cursor-pointer text-[16px] ${active === nav.title ? "text-white" : "text-dimWhite"} ${index === navLinks.length - 1 ? "mr-12" : "mr-10"}`}>
              <button onClick={handleSignOut} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Sign Out</button>
            </li>
          ) : (
            <li key={nav.id} className={`font-poppins font-normal cursor-pointer text-[16px] ${active === nav.title ? "text-white" : "text-dimWhite"} ${index === navLinks.length - 1 ? "mr-12" : "mr-10"}`}>
              {nav.title !== "Sign Out" ? <Link to="/myaccount">{nav.title}</Link> : null}
            </li>
          )
        ))}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div className={`${!toggle ? "hidden" : "flex"} p-6 bg-gray-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => (
              (nav.title === "Login" && !user) ? null : (nav.title === "Login" && user) ? (
                <li key={nav.id} className={`font-poppins font-medium cursor-pointer text-[16px] ${active === nav.title ? "text-white" : "text-dimWhite"} ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}>
                  <button onClick={handleSignOut} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>Sign Out</button>
                </li>
              ) : (
                <li key={nav.id} className={`font-poppins font-medium cursor-pointer text-[16px] ${active === nav.title ? "text-white" : "text-dimWhite"} ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}>
                  {nav.title !== "Sign Out" ? <Link to="/myaccount">{nav.title}</Link> : null}
                </li>
              )
            ))}
          </ul>
        </div>
      </div>
    </nav>
);
};

export default DashNavbar;