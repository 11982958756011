import { useState, useContext } from 'react'; // Remove duplicate imports
import { Link, useNavigate } from "react-router-dom";
import { close, sstech_scrnshot2, menu } from '../assets';
import { navLinks } from '../constants';
import axios from 'axios';
import { UserContext } from '../context/userContext';

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const { user, setUser } = useContext(UserContext); // Get user context and setUser function
  const navigate = useNavigate(); // Initialize the navigate function

  const handleSignOut = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    try {
      const response = await axios.get('/signout'); // Send a sign-out request to the server
      const { message } = response.data;
  
      // Display a success message or handle other logic as needed
      console.log(message);
  
      setUser(null); // Clear the user context locally
      navigate('/'); // Redirect the user to the home page
    } catch (error) {
      console.error('Sign out error:', error);
      // Handle error or display an error message to the user
    }
  };

  return (
    <nav className="w-full flex py-6 justify-between items-center navbar">
      <img src={sstech_scrnshot2} alt="sstech" className="w-[130px] h-auto rounded-md" />

      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => {
          if (nav.title === "My Account" && !user) {
            return null; // Hide "My Account" when user is not logged in
          }
          return (
            <li
              key={nav.id}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                active === nav.title ? "text-white" : "text-dimWhite"
              } ${index === navLinks.length - 1 ? "mr-12" : "mr-10"}`}
              onClick={() => {
                setActive(nav.title);
                if (nav.title === "Home") {
                  navigate('/');
                }
              }}
            >
              {/* Check if it's the "Login" or "Sign Out" link */}
              {nav.title === "Login" && !user && (
                <button onClick={() => navigate('/login')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>{nav.title}</button>
              )}
              {nav.title === "Sign Out" && user && (
                <button onClick={handleSignOut} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>{nav.title}</button>
              )}
              {nav.title !== "Login" && nav.title !== "Sign Out" && (
                <a href={`#${nav.id}`}>{nav.title}</a>
              )}
            </li>
          );
        })}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-gray-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => {
              if (nav.title === "My Account" && !user) {
                return null; // Hide "My Account" when user is not logged in
              }
              return (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-dimWhite"
                  } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                  onClick={() => {
                    setActive(nav.title);
                    if (nav.title === "Home") {
                      navigate('/');
                    }
                  }}
                >
                  {/* Check if it's the "Login" or "Sign Out" link */}
                  {nav.title === "Login" && !user && (
                    <button onClick={() => navigate('/login')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>{nav.title}</button>
                  )}
                  {nav.title === "Sign Out" && user && (
                    <button onClick={handleSignOut} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>{nav.title}</button>
                  )}
                  {nav.title !== "Login" && nav.title !== "Sign Out" && (
                    <a href={`#${nav.id}`}>{nav.title}</a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
