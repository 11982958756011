import React, { useState } from "react";
import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";
import styles from "../style";
import {toast} from 'react-hot-toast'

import { motion } from "framer-motion"; // Import motion from framer-motion


export default function Login() {
  const navigate = useNavigate()
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const loginUser = async (e) => {
    e.preventDefault()
    const {email, password} = data
    try{
      const {data} = await axios.post('/login', {
        email,
        password
      })

      if(data.error){
        toast.error(data.error)
      } else{
        setData({})
        navigate('/dashboard')
        window.location.reload();
      }

    }catch(error){

    }
    
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }} // Initial animation properties
      animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
      transition={{ duration: 0, delay: 0 }} // Transition duration and delay
      className={`${styles.flexCenter} ${styles.paddingY} flex-col bg-black text-white h-screen`}
    >
      <form onSubmit={loginUser} className={`${styles.flexCenter} flex-col`}>
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.5 }} // Transition duration and delay
        >
          {/* <label>Email: </label> */}
          <input
            type="email"
            placeholder="Enter email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            className={`${styles.input} ${styles.marginY} text-white`}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.7 }} // Transition duration and delay
        >
          {/* <label>Password: </label> */}
          <input
            type="password"
            placeholder="Enter password"
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            className={`${styles.input} ${styles.marginY} text-white`}
          />
        </motion.div>
        <motion.button
          type="submit"
          className={`${styles.button} ${styles.marginY}`}
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.9 }} // Transition duration and delay
        >
          Login
        </motion.button>
      </form>
  
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Initial animation properties
        animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
        transition={{ duration: 1, delay: 1.1 }} // Transition duration and delay
      >
        <Link to="/signup" className={`${styles.link} ${styles.marginY}`}>
          Don't have an account? Sign Up!
        </Link>
      </motion.div>
    </motion.div>
  );
}
