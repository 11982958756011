import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "../style";
import {toast} from 'react-hot-toast';
import { motion } from "framer-motion"; // Import motion from framer-motion


export default function SignUp() {
  const navigate = useNavigate()
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
  });
  
  const registerUser = async (e) => {
    e.preventDefault();
    const {name, email, password} = data
    try{
      const {data} = await axios.post('/signup', {
        name, email, password
      })
      if(data.error){
        toast.error(data.error)
      } else{
        setData({})
        toast.success('Login Successful. Welcome!')
        navigate('/login') //edit this if needed to change actual navigation
      }
    } catch (error){
      console.log(error)
    }
  }


  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }} // Initial animation properties
      animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
      transition={{ duration: 0, delay: 0 }} // Transition duration and delay
      className={`${styles.flexCenter} ${styles.paddingY} flex-col bg-black text-white h-screen`}
    >
      <form onSubmit={registerUser} className={`${styles.flexCenter} flex-col`}>
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.5 }} // Transition duration and delay
        >
          {/* <label htmlFor="name">Name:</label> */}
          <input
            type="text"
            placeholder="Enter name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            className={`${styles.input} ${styles.marginY} text-white`}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.7 }} // Transition duration and delay
        >
          {/* <label htmlFor="email">Email:</label> */}
          <input
            type="email"
            placeholder="Enter email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            className={`${styles.input} ${styles.marginY} text-white`}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 0.9 }} // Transition duration and delay
        >
          {/* <label htmlFor="password">Password</label> */}
          <input
            type="password"
            placeholder="Enter password"
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            className={`${styles.input} ${styles.marginY} text-white`}
          />
        </motion.div>
        <motion.button
          type="submit"
          className={`${styles.button} ${styles.marginY}`}
          initial={{ opacity: 0, y: -50 }} // Initial animation properties
          animate={{ opacity: 1, y: 0 }} // Animation properties to animate to
          transition={{ duration: 1, delay: 1.1 }} // Transition duration and delay
        >
          Register!
        </motion.button>
      </form>
    </motion.div>
  );
}


// Original
// return (
//   <div className={`${styles.flexCenter} ${styles.paddingY} flex-col bg-black text-white h-screen`}>
//     <form onSubmit={registerUser} className={`${styles.flexCenter} flex-col`}>
//       <label htmlFor="name">Name:</label>
//       <input type="text" placeholder="Enter name..." value={data.name} onChange={(e) => setData({...data, name: e.target.value})} 
//         className={`${styles.input} ${styles.marginY} text-black`}
//       />
//       <label htmlFor="email">Email:</label>
//       <input type="email" placeholder="Enter email..." value={data.email} onChange={(e) => setData({...data, email: e.target.value})} 
//         className={`${styles.input} ${styles.marginY} text-black`}
//       />
//       <label htmlFor="password">Password:</label>
//       <input type="password" placeholder="Enter password..." value={data.password} onChange={(e) => setData({...data, password: e.target.value})} 
//         className={`${styles.input} ${styles.marginY} text-black`}
//       />
//       <button type="submit" className={`${styles.button} ${styles.marginY}`}>Register!</button>
//     </form>
//   </div>
// );