import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useContext } from 'react';
import { UserContext } from '../context/userContext';

const Dashboard = () => {
  const controls = useAnimation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const interval = setInterval(() => {
      controls.start({ y: [0, -15, 0], transition: { duration: 3, repeat: Infinity } });
    }, 3000);

    return () => clearInterval(interval);
  }, [controls]);

  return (
    <section id="dashboard" className={`flex paddingY paddingX2`}>
      <div className={`flex-1 flexCenter flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-col">
          {!!user && (
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
              className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] mb-2 text-center"
            >
              Hi {user.user}!
            </motion.h1>
          )}
        </div>
      </div>
      <motion.div
        animate={controls}
        className={`flex-1 flex flexCenter paddingY md:ml-20 ml-0 md:mt-[30px] mt-10 relative`}
      >
        {/* Your additional content here */}
      </motion.div>
    </section>
  );
};

export default Dashboard;
