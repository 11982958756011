import { motion, useAnimation } from "framer-motion";
import { ITCompPhoto2 } from "../assets";
import GetStarted from "./GetStarted";
import styles from "../style";
import React, { useEffect } from 'react';

const Hero = () => {
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      controls.start({ y: [0, -15, 0], transition: { duration: 3, repeat: Infinity } });
    }, 3000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, [controls]);

  return (
    <section id="home" className={`flex ${styles.paddingY} ${styles.paddingX2}`}>
      <div className={`flex-1 ${styles.flexCenter} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-col">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] mb-2 text-center"
          >
            <span className="inline-block">Next</span> <br className="sm:block hidden" />{" "}
            <span className="text-gradient-gold">Generational</span>{" "}
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] mb-2 text-center"
          >
            Tech Consulting.
          </motion.h1>
        </div>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
          className={`${styles.paragraph}${styles.paddingY}  max-w-[470px] mt-5 text-center`}
        >
          Our team of experts at SS Technology Consultants Inc. are dedicated to helping businesses navigate the ever-evolving landscape of digital transformation.
        </motion.p>
      </div>
      <motion.div
        animate={controls}
        className={`flex-1 flex ${styles.flexCenter} ${styles.paddingY} md:ml-20 ml-0 md:mt-[30px] mt-10 relative`}
      >
        <div className="flex justify-center mt-8">
          <motion.img
            src={ITCompPhoto2}
            alt="billing"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.5 }}
            className="w-[50%] h-[100%] relative z-[7]"
          />
        </div>
      </motion.div>
      <div className={`ss:hidden ${styles.flexCenter}`}>
        <GetStarted />
      </div>
    </section>
  );
};

export default Hero;
