import { motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { features } from "../constants";
import styles, { layout } from "../style";
import Button from "./Button";
import styled from "styled-components";

// Styled component to override the CardActionArea focus highlight
const StyledCardActionArea = styled(motion.div)`
  .MuiCardActionArea-focusHighlight {
    background: transparent;
  }
`;

const FeatureCard = ({ icon, title, content }) => (
  <div className="feature-card-container" style={{ overflow: "hidden" }}>
    <br />
    <StyledCardActionArea
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className={`flex flex-row p-1 rounded-[20px] mb-6 feature-card ${styles.boxWidth} mt-8`}
      style={{ backgroundColor: "transparent" }} // Set background color to transparent by default
    >
      <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
        <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
      </div>
      <div className={`flex-1 flex flex-col ml-3 ${layout.marginY} ${layout.paddingX}`}>
        <h4 className={`font-poppins font-semibold text-white text-[9px] leading-[16px] mb-1 ${styles.heading3}`}>
          {title}
        </h4>
        <p className={`font-poppins font-normal text-dimWhite text-[12px] leading-[24px] ${styles.paragraph}`}>
          {content}
        </p>
      </div>
    </StyledCardActionArea>
  </div>
);

const Business = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      // Animate when the component is in view
      // You can add your animation logic here
    }
  }, [inView]);

  return (
    <section id="features" className={`${styles.paddingY2} ${layout.section}`}>
      <br /> <br />
      <div ref={ref} className={layout.sectionInfo}>
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
          className={styles.heading2}
        >
          Need to improve <br className="sm:block hidden" /> the company?  
          <br />
          SSTech.     
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: 0.5 }}
          className={`${styles.paragraph} max-w-[400px] mt-5`}
        >
          With the right consultants, 
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: .75 }}
          className={`${styles.paragraph} max-w-[550px] mt-5`}
        >
          <strong>Elevate your Innovation.</strong> 
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: 1.0 }}
          className={`${styles.paragraph} max-w-[400px] mt-5`}
        >
          Unlock a realm of possibilities 
          and propel your business into new heights.
        </motion.p>

        <Button styles={`mt-10`} />
      </div>

      <div className={`${layout.sectionImg} flex-col ml-10`}>
        {features.map((feature, index) => (
          <motion.div
            key={feature.id}
            whileHover={{ scale: 1.05 }} // Scale up on hover
            whileTap={{ scale: 0.95 }} // Scale down on tap
            className="feature-card-container"
            //style={{ overflow: "hidden" }} // Apply inline style
          >
            <FeatureCard {...feature} index={index} />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Business;
