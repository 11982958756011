import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { close, sstech_scrnshot2, menu } from '../assets';
import { darkNavLinks } from '../constants';
import styles from '../style'; // Import styles

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <nav className={`${styles.flex} ${styles.py6} ${styles.justifyBetween} ${styles.itemsCenter} navbar bg-black text-white`}>
      {/* Wrap the img in an anchor tag */}
      <div >
        <a href="/">
          <img src={sstech_scrnshot2} alt="sstech" onClick={handleLogoClick} className="w-[130px] h-auto rounded-md" />
        </a>
      </div>
      <div className={`${styles.smHidden} ${styles.flex1} ${styles.justifyEnd} ${styles.itemsCenter}`}>
        {/* <h1 className={`${styles.flex} `}>Menu</h1>
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        /> */}

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-gray-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
        >
          <ul className={`${styles.listNone} ${styles.flex} ${styles.justifyEnd} ${styles.itemsStart} ${styles.flex1} ${styles.flexCol}`}>
            {darkNavLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`${styles.fontPoppins} ${styles.fontMedium} cursor-pointer text-[16px] ${
                  active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === darkNavLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => {
                  setActive(nav.title);
                  if (nav.title === "Home") {
                    navigate('/');
                  }
                }}
              >
                {/* Check if it's the "Login" link */}
                {nav.title === "Login" ? (
                  <button onClick={() => navigate('/login')} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>{nav.title}</button>
                ) : (
                  <a href={`#${nav.id}`}>{nav.title}</a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
