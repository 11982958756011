import { motion } from "framer-motion";
import { feedback } from "../constants";
import styles from "../style";
import FeedbackCard from "./FeedbackCard";

const Testimonials = () => (
  <section id="clients" className={`${styles.paddingY} ${styles.paddingX2} flex-col relative `}>
    <div className="absolute z-[0] w-[60%] h-[60%] bottom-40" />
    <div className="">
      <h2 className={`${styles.heading2} text-center`}>
        What People are saying about us.
      </h2>
      <div className="w-full md:mt-0 mt-6"></div>
    </div>
    <div className={`${styles.flexCenter} flex feedback-container relative z-[1]`}>
      {feedback.map((card) => (
        <motion.div
          key={card.id}
          whileHover={{ scale: 1.05 }} // Scale up on hover
          whileTap={{ scale: 0.95 }} // Scale down on tap
          className="feedback-card-container"
        >
          <FeedbackCard {...card} />
        </motion.div>
      ))}
    </div>
  </section>
);

export default Testimonials;
