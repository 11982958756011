import styles from "../style";
import { sstech_scrnshot2 } from "../assets";
import { footerLinks, socialMedia } from "../constants";

const DarkFooter = () => (
  <footer className={`${styles.flexCenter} ${styles.paddingY} flex-col bg-black text-white`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-4 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-6">
        <img
          src={sstech_scrnshot2}
          alt="sstech"
          className="w-[150px] h-[40px] object-contain"
        />
        <p className={`${styles.paragraph} mt-2 max-w-[240px] text-sm`}>
          A new way for to make tech consulting easy, reliable and trustworthy.
        </p>
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-6">
        {footerLinks.map((footerlink) => (
          <div key={footerlink.title} className={`flex flex-col ss:my-0 my-2 min-w-[120px]`}>
            <h4 className="font-poppins font-medium text-sm leading-[1.5rem] text-white">
              {footerlink.title}
            </h4>
            <ul className="list-none mt-2">
              {footerlink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[14px] leading-[1.5rem] text-dimWhite hover:text-secondary cursor-pointer ${
                    index !== footerlink.links.length - 1 ? "mb-2" : "mb-0"
                  }`}
                >
                  {link.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-4 border-t-[1px] border-t-[#3F3E45]">
      <p className="font-poppins font-normal text-center text-sm leading-[1.5rem]">
        Copyright Ⓒ 2024 SS Technology Consultants Inc. All Rights Reserved.
      </p>

      <div className="flex flex-row md:mt-0 mt-4">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[18px] h-[18px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-4" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
    </div>
  </footer>
);

export default DarkFooter;
