import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login, SignUp, Dashboard } from "./components";

import LoginLayout from './layouts/LoginLayout'; // Import the LoginLayout component
import HomeLayout from './layouts/HomeLayout';
import SignUpLayout from './layouts/SignUpLayout';
import DashboardLayout from './layouts/DashboardLayout';

import axios from 'axios' 


import {Toaster} from 'react-hot-toast'
import { UserContextProvider } from './context/userContext';

axios.defaults.baseURL = 'http://localhost:8000';
//axios.defaults.baseURL = 'https://www.sstechconsultants.com';
axios.defaults.withCredentials = true

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Toaster position='bottom-right' toastOptions ={{duration: 2000}} />
        <Routes>
          <Route path="/" element={<HomeLayout />} />
          <Route path="/login" element={<LoginLayout><Login /></LoginLayout>} />
          <Route path="/signup" element={<SignUpLayout><SignUp /></SignUpLayout>} />
          <Route path="/dashboard" element={<DashboardLayout><Dashboard /></DashboardLayout>}/>
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

export default App;



// supposed to be in json file according to gpt

//"homepage": "https://sanjaykarun4444.github.io/sstech-website2",

//  MONGODB Password: d1qVYO2Bj0NUiT0b




// import React from 'react'
// import styles from './style';
// import LoginLayout from './layouts/LoginLayout';

// import { Billing, Business, CardDeal, Clients, CTA, Footer, Navbar, Stats, Testimonials, Hero, Login, Home, SignUp } from "./components";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// const App = () => (
//     <div className="bg-primary w-full overflow-hidden">
//       <div className = {`${styles.paddingX} ${styles.flexCenter}`}>
//         <div className ={`${styles.boxWidth}`}>
//           <Router>
//           <Navbar/>
//             <Routes>
//               <Route path="/login" element={<LoginLayout><Login /></LoginLayout>} />
//               <Route path="/SignUp" element={<SignUp />}/>
//             </Routes>
//           </Router>
//         </div>
//       </div>

//       <div className = {`bg-primary  ${styles.flexStart}`}>
//         <div clasName = {`${styles.boxWidth}`}>
//           <Hero/>
//         </div>
//       </div>

//       <div className = {`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
//         <div clasName = {`${styles.boxWidth}`}>
//           <Stats/>
//           <Business/>
//           <Billing/>
//           <CardDeal/>
//           <Testimonials/>
//           <Clients/>
//           <CTA/>
//           <Footer/>
//         </div>
//       </div>
//     </div>
// );


// const HomeLayout = () => (
//   <div>
//     <Hero />
//     <Stats />
//     <Business />
//     <Billing />
//     <CardDeal />
//     <Testimonials />
//     <Clients />
//     <CTA />
//     <Footer />
//   </div>
// );

// export default App
