// LoginLayout.jsx
import styles from '../style';
import React from 'react';
import { DarkNavBar,DarkFooter, BlankBlack } from '../components'; // Import necessary components

const SignUpLayout = ({ children }) => {
  return (
    <div className="signup-layout">
      {/* Your layout components */}
      <DarkNavBar />
      {children}

      <DarkFooter />
    </div>
  );
};

export default SignUpLayout;
