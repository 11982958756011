const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",
  
    heading2: "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    heading3: "font-poppins font-semibold xs:text-[30px] text-[22px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    paragraph: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",
    heading4: "xs:text-[30px] text-[22px] text-black",
    smallheading: "xs:text-[12px] text-[12px] text-black",
  
    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
  
    paddingX: "sm:px-16 px-6",
    padding2X: "sm:px-8 px-3",
    paddingX2: "sm:px-32 px-12",
    paddingY: "sm:py-16 py-6",
    paddingY2: "sm:py-8 py-3",
    padding: "sm:px-16 px-6 sm:py-12 py-4",
  
    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
    marginY2: "sm-my-32 my-12",
  };
  
  export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY} px-20`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative justify-end`,

    sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
  };

  // styles.js

export const loginLayout = {
  container: "flex justify-center items-center h-screen", // Adjust as needed
  formContainer: "w-full max-w-md p-6", // Adjust as needed
};

export const footerStyles = `
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the footer stays above other content */
  }
`;


  
  export default styles;